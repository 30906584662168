import React, { useEffect, useState } from "react";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { ForwardSelection } from "@src/features/staffing_request_details/components/forward_selection";
import { VacancySelection } from "@src/features/staffing_request_details/components/vacancy_selection";
import {
  setDetailsModalVisible,
  setNextStepModalVisible,
} from "@src/services/requestOverviewSlice";
import { useGetTeamLeadsQuery } from "@src/services/slices/employeesSlice";
import { useAddNoteMutation } from "@src/services/slices/notesApiSlice";
import {
  useForwardRequestMutation,
  useProposeTeamMemberMutation,
  useTeamLeadDeclineRequestMutation,
  useUpdateInRecruitmentMutation,
} from "@src/services/slices/teamLeadsApi";
import { useAppDispatch } from "@src/setupStore";
import {
  Employee,
  Note,
  RequestOverviewDetails,
  SolutionType,
} from "@src/types";
import { getInitials } from "@src/utils/helper";
import { Avatar, Form, message, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import "./next_step_modal.less";

interface NextStepModalProps {
  request: RequestOverviewDetails;
  selectedSolutionType: SolutionType;
  loggedInUser: Employee;
  nextStepModalVisible: boolean;
  setNextStepModalVisibility: (value: boolean) => void;
}

const NextStepModal: React.FC<NextStepModalProps> = ({
  request,
  selectedSolutionType,
  loggedInUser,
  nextStepModalVisible,
  setNextStepModalVisibility,
}) => {
  const [form] = Form.useForm<{ comment: string }>();

  //local states
  const [nextStepModalButtonDisabled, setNextStepModalButtonDisabled] =
    useState(false);
  const [selectedVacancyId, setSelectedVacancyId] = useState<number | null>(
    null
  );
  const [selectedTeamLeadId, setSelectedTeamLeadId] = useState<number | null>(
    null
  );

  const dispatch = useAppDispatch();

  //hooks
  const { data: teamLeads } = useGetTeamLeadsQuery();
  const [forwardRequest] = useForwardRequestMutation();
  const [addNote] = useAddNoteMutation();
  const [proposeTeamMember] = useProposeTeamMemberMutation();
  const [updateInRecruitment] = useUpdateInRecruitmentMutation();
  const [declineRequest] = useTeamLeadDeclineRequestMutation();

  //local variables
  const requestTitle = `${request.projectRoleRequestId} - ${request.genericRole}`;

  useEffect(() => {
    // the confirm button should be disabled for forwarded, vacancy selection and later for team member
    // propose vacancy will be enabled when a vacancy is selected and forward will be enabled when a team lead is selected
    if (
      selectedSolutionType === SolutionType.FORWARD ||
      selectedSolutionType === SolutionType.VACANCY
    ) {
      setNextStepModalButtonDisabled(true);
    }
  }, []);

  /**
   * Handle the cancellation of the next step Modal.
   */
  function onCancelNextStepModal() {
    dispatch(setNextStepModalVisible(false));
    setNextStepModalVisibility(false);
  }

  /**
   * Handle the confirmation of the next step Modal and call the appropriate function based on the selected solution.
   *
   */
  async function onConfirmSend() {
    const note: string = form.getFieldValue("comment");

    switch (selectedSolutionType) {
      case SolutionType.VACANCY:
        await handleProposeVacancyConfirmation();
        break;
      case SolutionType.FORWARD:
        await handleForwardRequestConfirmation();
        break;
      case SolutionType.IN_RECRUITMENT:
        await handleMarkInRecruitmentConfirmation();
        break;
      case SolutionType.DECLINE:
        await handleDeclineRequestConfirmation();
        break;
      default:
        break;
    }

    if (note) await createNote(note);
    dispatch(setDetailsModalVisible(false));
    dispatch(setNextStepModalVisible(false));
    setNextStepModalVisibility(false);
  }

  /**
   * Handle the proposed vacancy solution. This will propose a vacancy to cover the project.
   *
   */
  async function handleProposeVacancyConfirmation() {
    try {
      await proposeTeamMember({
        roleRequestId: request.projectRoleRequestId,
        teamMemberId: selectedVacancyId,
      }).unwrap();
      message.success("Vacancy selected successfully");
    } catch (error) {
      console.log(error);
    }
  }

  /**
   *  Handle the forward request solution. This will forward the request to another team lead.
   *
   */
  async function handleForwardRequestConfirmation() {
    try {
      await forwardRequest({
        roleRequestId: request.projectRoleRequestId,
        teamLeadId: selectedTeamLeadId,
      }).unwrap();
      message.success("Request forwarded successfully");
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Handle the mark in recruitment solution. This will mark the request as in recruitment.
   *
   */

  async function handleMarkInRecruitmentConfirmation() {
    try {
      await updateInRecruitment({
        roleRequestId: request.projectRoleRequestId,
        inRecruitment: true,
      }).unwrap();
      message.success("Request marked as in recruitment successfully");
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Handle the decline request solution. This will decline the request and propose a different workload.
   *
   */
  async function handleDeclineRequestConfirmation() {
    try {
      await declineRequest({
        payLoad: {
          id: request.projectRoleRequestId,
          requestAllocationPayload: [],
        },
        params: { declineRequestType: "propose" },
      }).unwrap();
      message.success("Request declined successfully");
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Create a note and save it.
   *
   * @param noteText  The note text to be saved.
   */
  async function createNote(noteText: string) {
    const note: Note = {
      employeeId: loggedInUser.employeeId,
      createdDate: new Date().toISOString(),
      roleRequestId: request.projectRoleRequestId,
      text: noteText,
      employeeFullName: loggedInUser.fullName,
      createdBy: loggedInUser.employeeId,
    };

    addNote(note)
      .unwrap()
      .then(() => {
        message.success("Successfully saved note", 2);
      })
      .catch((err) => console.log("error", err));
  }

  /**
   * Generate the children for the next step modal based on the selected solution.
   */
  function generateChildren() {
    switch (selectedSolutionType) {
      case SolutionType.PROPOSE_TEAM_MEMBER: {
        return "will be implemented in another ticket";
      }
      case SolutionType.VACANCY: {
        return <VacancySelection onChange={onVacancySelected} />;
      }
      case SolutionType.FORWARD:
        return (
          <ForwardSelection
            onChange={onTeamLeadSelected}
            teamLeads={teamLeads}
          />
        );
      case SolutionType.IN_RECRUITMENT:
        return (
          <p>{`Are you sure you want to mark ‘${requestTitle}’ as in recruitment.`}</p>
        );
      case SolutionType.DECLINE:
        return null;
      case SolutionType.DECLINE_AND_PROPOSE:
        return null; //will be implemented in another ticket
      default:
        break;
    }
  }

  /**
   * Handle the vacancy selection and set the selected vacancy id.
   *
   * @param vacancyId   The selected vacancy id.
   */
  function onVacancySelected(vacancyId: number) {
    setNextStepModalButtonDisabled(!vacancyId);
    setSelectedVacancyId(vacancyId);
  }

  /**
   * Handle the team lead selection and set the selected team lead id.
   *
   * @param selectedTeamLeadId    The selected team lead id.
   */
  function onTeamLeadSelected(selectedTeamLeadId: number) {
    setNextStepModalButtonDisabled(!selectedTeamLeadId);
    setSelectedTeamLeadId(selectedTeamLeadId);
  }

  /**
   * Generate the title for the next step modal based on the selected solution.
   */
  function generateTitle() {
    switch (selectedSolutionType) {
      case SolutionType.PROPOSE_TEAM_MEMBER: {
        return `Propose team member(s) for ${requestTitle}`; //the component will be implemented in another ticket
      }
      case SolutionType.VACANCY: {
        return `Select Vacancy for ${requestTitle}`;
      }
      case SolutionType.FORWARD:
        return `Forward request ${requestTitle}`;
      case SolutionType.IN_RECRUITMENT:
        return `Mark '${requestTitle}' as in recruitment`;
      case SolutionType.DECLINE:
        return `Decline ${requestTitle}`;
      case SolutionType.DECLINE_AND_PROPOSE:
        return `Decline ${requestTitle}`; //the component will be implemented in another ticket
      default:
        break;
    }
  }

  return (
    <Modal
      open={nextStepModalVisible}
      centered
      width={900}
      maskClosable={false}
      closable={false}
      title={generateTitle()}
      okButtonProps={{
        icon: <CheckOutlined />,
        disabled: nextStepModalButtonDisabled,
        "data-testid": "confirm-button",
      }}
      onOk={() => onConfirmSend()}
      okText="Confirm"
      cancelButtonProps={{
        icon: <CloseOutlined />,
        "data-testid": "close-next-step-modal",
      }}
      onCancel={onCancelNextStepModal}
      className="next-step-modal"
    >
      {generateChildren()}
      <div className="next-step-modal__comment-section">
        <Avatar className="next-step-modal__comment-box-avatar">
          {getInitials(loggedInUser.fullName)}
        </Avatar>
        <Form layout="vertical" form={form}>
          <Form.Item label="Add Comment" name="comment">
            <TextArea
              autoSize={false}
              rows={4}
              style={{ resize: "none" }}
              data-testid="comment-input"
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default NextStepModal;
