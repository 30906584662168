import React from "react";

import { Employee } from "@src/types";
import { Form, Select } from "antd";
import dayjs from "dayjs";
import "./vacancy_selection.less";
import { useGetVacanciesQuery } from "@src/services/slices/teamLeadsApi";
import { useAuthentication } from "@src/auth/useAuthentication";
import Cookies from "universal-cookie";

const cookies = new Cookies();

interface VacancySelectionProps {
  onChange: (value: number) => void;
}

const VacancySelection: React.FC<VacancySelectionProps> = ({ onChange }) => {
  const loggedInUser = cookies.get("loggedInuser");

  //rtk queries
  const { data: vacancies } = useGetVacanciesQuery(loggedInUser?.employeeId, {
    skip: !loggedInUser?.employeeId,
  });

  function getVacancyOptionLabel(member: Employee): string {
    const startDate = member.positionStartDate
      ? `${dayjs(member.positionStartDate).format("DD/MM/YYYY")} - `
      : "";
    const endDate = member.positionEndDate
      ? `${dayjs(member.positionEndDate).format("DD/MM/YYYY")} - `
      : "";

    const position = `${member.positionText} (${member.deskId}) - `;
    const availablePercentage = `${member.totalAvailablePercentage}% - `;
    const contractType = member.contractType
      ? member.contractType
      : "no contract type";

    return `${startDate}${endDate}${position}${availablePercentage}${contractType}`;
  }

  return (
    <div className="vacancy-selection">
      <div className="vacancy-selection__input">
        <Form layout="vertical">
          <Form.Item label="Vacancy" data-testid="suggest-vacancy-selection">
            <Select
              showSearch
              allowClear
              onChange={(selectedVacancyId: number) =>
                onChange(selectedVacancyId)
              }
              onClear={() => onChange(null)}
              placeholder="Select vacancy"
              optionFilterProp="label"
              options={vacancies?.map((vacancy: Employee) => ({
                value: vacancy.employeeId,
                label: getVacancyOptionLabel(vacancy),
              }))}
            />
          </Form.Item>
        </Form>
      </div>
      <div className="vacancy-selection__allocation-table">
        here will be the allocation table
      </div>
    </div>
  );
};

export default VacancySelection;
