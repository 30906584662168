import { useEffect, useState } from "react";

import { REQUEST_STATUS } from "@src/constants";
import {
  gatherActiveFilters,
  onChangeQuickFilter,
  onColumnConfigChange,
  onColumnFilterChange,
  onFilterChange,
  resetAllActiveFilters,
} from "@src/features/table_filtering/utils/base_state_handler_utils";
import {
  clearCustomTableFilterProperty,
  clearFilter,
  RESOURCE_PLAN_FILTER_TYPES_V2,
} from "@src/services/resourcePlanSliceV2";
import { useAppDispatch, useAppSelector } from "@src/setupStore";
import { ActiveFilter } from "@src/types";
import { TabItem } from "@src/types/antd_types";
import { ColDef, GridApi } from "ag-grid-community";

interface UseResourcePlanStateHandlerProps {
  gridApi: GridApi;
  columnDefs: ColDef[];
}

interface UseResourcePlanStateHandlerResponse {
  activeFilters: ActiveFilter[];
  resetActiveFilters: (key?: string, value?: string) => void;
  setTableRendered: () => void;
}

export const useResourcePlanStateHandler = ({
  gridApi,
  columnDefs,
}: UseResourcePlanStateHandlerProps): UseResourcePlanStateHandlerResponse => {
  const [isTableRendered, setIsTableRendered] = useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<ActiveFilter[]>([]);
  const dispatch = useAppDispatch();

  const {
    resourcePlanTableState: {
      quickFilter,
      customTableFilters,
      currentTabConfig,
      columns,
      columnConfigs,
    },
  } = useAppSelector((state) => state.resourcePlanSliceV2);

  /**
   * Load filter state from store to grid
   */
  useEffect(() => {
    if (!gridApi && !isTableRendered) return;

    onChangeQuickFilter(quickFilter, gridApi, null);
    onColumnFilterChange(columns, gridApi, columnDefs);

    onTabChange(currentTabConfig, gridApi);

    for (const key of Object.keys(customTableFilters)) {
      const values: string[] = customTableFilters[key];
      onFilterChange(values, key, gridApi, null);
    }

    onColumnConfigChange(columnConfigs, gridApi);

    const activeFilters = gatherActiveFilters(customTableFilters);
    setActiveFilters(activeFilters);
  }, [
    columns,
    quickFilter,
    customTableFilters,
    currentTabConfig,
    gridApi,
    isTableRendered,
    columnConfigs,
  ]);

  function onTabChange(currentTabConfig: TabItem, gridApi: GridApi): void {
    if (currentTabConfig?.key === "resource-plan-inbox") {
      const status: string[] = Object.values(REQUEST_STATUS).filter(
        (status) => status !== REQUEST_STATUS.DRAFT
      );
      onFilterChange(
        status,
        RESOURCE_PLAN_FILTER_TYPES_V2.STATUS,
        gridApi,
        null
      );
    } else if (currentTabConfig?.key === "drafts") {
      onFilterChange(
        [REQUEST_STATUS.DRAFT],
        RESOURCE_PLAN_FILTER_TYPES_V2.STATUS,
        gridApi,
        null
      );
    }
  }

  function resetActiveFilterByKey(key: string, value: string): void {
    const valueToBeCleared = { key, value };
    dispatch(clearCustomTableFilterProperty(valueToBeCleared));
  }

  function resetActiveFilters(key?: string, value?: string): void {
    if (key !== undefined && value !== undefined) {
      resetActiveFilterByKey(key, value);
    } else {
      resetAllActiveFilters(gridApi);
      onTabChange(currentTabConfig, gridApi);
      dispatch(clearFilter());
    }
  }

  return {
    activeFilters,
    resetActiveFilters,
    setTableRendered: () => setIsTableRendered(true),
  };
};
