import React, { useEffect, useState } from "react";

import "./propose_solution_tab.less";
import { useAuthentication } from "@src/auth/useAuthentication";
import { NextStepModal } from "@src/features/staffing_request_details/components/next_step_modal";
import { getProposeSolutionSettings } from "@src/features/staffing_request_details/utils/propose_solution_utils";
import { setProposedSolutionType } from "@src/services/requestOverviewSlice";
import { useGetVacanciesQuery } from "@src/services/slices/teamLeadsApi";
import { RootState, useAppDispatch, useAppSelector } from "@src/setupStore";
import {
  Employee,
  ProposeSolutionSettings,
  RequestOverviewDetails,
  SolutionType,
} from "@src/types";
import { Form, Radio, Space, Tooltip } from "antd";
import Cookies from "universal-cookie";

const cookies = new Cookies();

interface ProposeSolutionProps {
  request: RequestOverviewDetails;
}

const ProposeSolution: React.FC<ProposeSolutionProps> = ({ request }) => {
  const dispatch = useAppDispatch();

  //states
  const [proposeSolutionSettings, setProposeSolutionSettings] = useState<
    ProposeSolutionSettings[]
  >(getProposeSolutionSettings());

  //hooks
  const loggedInUser = cookies.get("loggedInuser");
  const [form] = Form.useForm<{ selectedSolution: string }>();

  //local states
  const [nextStepModalVisible, setNextStepModalVisible] =
    useState<boolean>(false);

  //rtk selector
  const { proposeSolutionModal, modalSettings } = useAppSelector(
    (state: RootState) => state.requestOverviewSlice
  );

  //rtk queries
  const { data: vacancies } = useGetVacanciesQuery(loggedInUser?.employeeId, {
    skip: !loggedInUser?.employeeId,
  });

  useEffect(() => {
    if (vacancies && proposeSolutionSettings.length > 0) {
      const vacancySettings = proposeSolutionSettings.find(
        (proposeSolutionSetting) =>
          proposeSolutionSetting.type === SolutionType.VACANCY
      );
      vacancySettings.disabled = false;
      setProposeSolutionSettings([...proposeSolutionSettings]);
    }
  }, [vacancies]);

  useEffect(() => {
    if (proposeSolutionModal.nextStepModalVisible) {
      setNextStepModalVisible(true);
    }
  }, [proposeSolutionModal.nextStepModalVisible]);

  useEffect(() => {
    if (!modalSettings.detailsModalVisible) {
      dispatch(setProposedSolutionType(null));
      form.resetFields();
    }
  }, [modalSettings.detailsModalVisible]);

  /**
   * Handle the proposed solution selection and set the proposed solution type.
   */
  function onProposeSolutionSelected() {
    dispatch(setProposedSolutionType(form.getFieldValue("selectedSolution")));
  }

  return (
    <div className="propose-solution-tab">
      <h4>Propose Solution</h4>
      {nextStepModalVisible && (
        <NextStepModal
          request={request}
          selectedSolutionType={form.getFieldValue("selectedSolution")}
          loggedInUser={loggedInUser}
          nextStepModalVisible={nextStepModalVisible}
          setNextStepModalVisibility={setNextStepModalVisible}
        />
      )}
      <Form form={form} onChange={onProposeSolutionSelected}>
        <Form.Item name="selectedSolution">
          <Radio.Group>
            <Space direction="vertical">
              {proposeSolutionSettings.map(
                (setting: ProposeSolutionSettings) => (
                  <Tooltip title={setting.disabledTooltip} key={setting.type}>
                    <Radio
                      key={setting.type}
                      value={setting.type}
                      disabled={setting.disabled}
                      data-testid={setting.type}
                    >
                      {setting.label}
                    </Radio>
                  </Tooltip>
                )
              )}
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProposeSolution;
