import React from "react";

import { Employee } from "@src/types";
import { Form, Select } from "antd";

interface ForwardSelectionProps {
  onChange: (value: number) => void;
  teamLeads: Employee[];
}

const ForwardSelection: React.FC<ForwardSelectionProps> = ({
  onChange,
  teamLeads,
}) => {
  return (
    <Form layout="vertical">
      <Form.Item label="Forward To" data-testid="forward-select-form">
        <Select
          placeholder="please select a team lead"
          showSearch
          allowClear
          onChange={onChange}
          data-testid="forward-select"
          optionFilterProp="label"
          options={teamLeads?.map((teamLead: Employee) => ({
            value: teamLead.employeeId,
            label: teamLead.fullName,
          }))}
        />
      </Form.Item>
    </Form>
  );
};

export default ForwardSelection;
