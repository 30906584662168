import React from "react";

import fixedbottom from "@src/assets/icons/fixedbottom-tech.svg";
import floating from "@src/assets/icons/floating-tech.svg";
import hydrogen from "@src/assets/icons/hydrogen.svg";
import GridItem from "@src/components/grid_label/grid_label";
import { ProjectPhaseChip } from "@src/components/project_phase_chip";
import { Status } from "@src/components/status";
import { RequestOverviewDetails } from "@src/types";
import { formatDate, getWorkloadStartDate } from "@src/utils/helper";
import { Card } from "antd";
import "./staffing_request_general_information_card.less";

interface StaffingRequestGeneralInformationCardProps {
  request: RequestOverviewDetails;
  status: string;
}

const technologyIcons = {
  Floating: floating,
  "Fixed bottom": fixedbottom,
  Hydrogen: hydrogen,
};

function getTechnologyName(technologies: string): string {
  if (!technologies) return "";
  return technologies
    .split(", ")
    .filter((tech) => tech !== "Hydrogen")
    .join(", ");
}

function getTechnologyIcon(technologies: string): string {
  if (!technologies) return "";
  if (technologies.includes("Hydrogen")) return hydrogen;
  const technologyName = getTechnologyName(technologies);
  return technologyIcons[technologyName] || "";
}

const StaffingRequestGeneralInformationCard: React.FC<
  StaffingRequestGeneralInformationCardProps
> = ({ request, status }) => {
  const scopeDescription =
    request?.childRequest?.scopeDescription ?? request?.scopeDescription;
  const specificRole =
    request?.childRequest?.specificRole ?? request?.specificRole;
  const projectIdentifier =
    request?.childRequest?.projectIdentifier ?? request?.projectIdentifier;

  return (
    <Card
      title={request.projectName}
      className="staffing-request-overview-general-information-card"
    >
      <div className="staffing-request-overview-general-information-card-table">
        <GridItem label="Phase">
          <>
            <ProjectPhaseChip
              projectPhase={request.projectPhase}
              compact={false}
            />
            {request.stageGateDate && (
              <span>{`(End: ${formatDate(request.stageGateDate)})`}</span>
            )}
          </>
        </GridItem>
        <GridItem label="Region / Country">
          {`${request.region} / ${request.country}`}
        </GridItem>
        <GridItem label="Scope Descriptions">{scopeDescription}</GridItem>
        <GridItem label="Status">
          <Status status={status} />
        </GridItem>
        <GridItem label="Technology">
          <div className="general-information-card-table-technology">
            {getTechnologyIcon(request.technologies) && (
              <img
                src={getTechnologyIcon(request.technologies)}
                alt="project phase"
                className="general-information-card-table-technology-icon"
              />
            )}
            <span className="general-information-card-table-technology-name">
              {getTechnologyName(request.technologies)}
            </span>
          </div>
        </GridItem>
        <GridItem label="Project Identifier">{projectIdentifier}</GridItem>
        <GridItem label="Project Lead">{request.projectLead}</GridItem>
        <GridItem label="Workload Start">
          {formatDate(getWorkloadStartDate(request.yearlyAllocations))}
        </GridItem>
        <GridItem label="Specific Role">{specificRole}</GridItem>
      </div>
    </Card>
  );
};

export default StaffingRequestGeneralInformationCard;
