import React from "react";

import {
  CloseOutlined,
  MinusCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { REQUEST_STATUS } from "@src/constants";
import {
  setDetailsModalVisible,
  setNextStepModalVisible,
  setProposedSolutionType,
  setActiveTabKey,
} from "@src/services/requestOverviewSlice";
import { RootState, useAppDispatch, useAppSelector } from "@src/setupStore";
import { RequestOverviewDetails } from "@src/types";
import { Button } from "antd";
import "./staffing_request_modal_action_handlers.less";

interface StaffingRequestModalActionHandlersProps {
  request: RequestOverviewDetails;
  onSend?: () => void;
}

const statusMap: { [key: string]: string } = {
  Requested: REQUEST_STATUS.REQUESTED,
  "In recruitment": REQUEST_STATUS.IN_RECRUITMENT,
  Confirmed: REQUEST_STATUS.CONFIRMED,
  Proposed: REQUEST_STATUS.PROPOSED,
};

const StaffingRequestModalActionHandlers: React.FC<
  StaffingRequestModalActionHandlersProps
> = ({ request }) => {
  const dispatch = useAppDispatch();

  const { proposeSolutionModal } = useAppSelector(
    (state: RootState) => state.requestOverviewSlice
  );

  function handleClose() {
    dispatch(setDetailsModalVisible(false));
    dispatch(setProposedSolutionType(null));
    dispatch(setActiveTabKey("0"));
  }

  function getActionsForRequest() {
    if (!request) return [];

    const commonActions: React.ReactNode[] = [
      <Button
        key="back"
        size={"large"}
        icon={<CloseOutlined />}
        onClick={handleClose}
        data-testid="close-request-details-button"
      >
        Cancel
      </Button>,
    ];

    const actions: React.ReactNode[] = [...commonActions];
    switch (statusMap[request.status]) {
      case REQUEST_STATUS.REQUESTED:
        actions.push(
          <Button
            key="save"
            type="primary"
            size={"large"}
            icon={<RightOutlined />}
            disabled={!proposeSolutionModal?.proposedSolutionType}
            onClick={() => dispatch(setNextStepModalVisible(true))}
            data-testid="next-step-button"
          >
            Next Step
          </Button>
        );
        break;
      case REQUEST_STATUS.IN_RECRUITMENT:
        actions.push(
          <Button
            key="removeFromRecruitment"
            type="primary"
            size={"large"}
            className="warning-button"
          >
            Remove from Recruitment List
          </Button>
        );
        break;
      case REQUEST_STATUS.CONFIRMED:
        actions.push(
          <Button
            key="takeBack"
            type="primary"
            size={"large"}
            className="warning-button"
            icon={<MinusCircleOutlined />}
          >
            Take Back Proposal
          </Button>
        );
        break;
      case REQUEST_STATUS.PROPOSED:
        actions.push(
          <Button
            key="takeBack"
            type="primary"
            size={"large"}
            className="warning-button"
            icon={<MinusCircleOutlined />}
          >
            Take Back Proposal
          </Button>
        );
        break;
      default:
        return [];
    }

    return actions;
  }

  return <>{getActionsForRequest()}</>;
};

export default StaffingRequestModalActionHandlers;
