import { SolutionType } from "@src/types";

export function getProposeSolutionSettings() {
  return [
    {
      type: SolutionType.PROPOSE_TEAM_MEMBER,
      label: "Propose team member(s)",
      disabled: true,
      disabledTooltip: "Will be implemented in another ticket",
    },
    {
      type: SolutionType.VACANCY,
      disabled: true,
      label: "Select a vacancy which could cover the project",
      disabledTooltip: "No vacancies available",
    },
    {
      type: SolutionType.FORWARD,
      label: "Forward the request to another team lead",
    },
    {
      type: SolutionType.IN_RECRUITMENT,
      label: "Mark as 'in recruitment' for all viewers",
    },
    {
      type: SolutionType.DECLINE,
      label: "Decline",
    },
    {
      type: SolutionType.DECLINE_AND_PROPOSE,
      label: "Decline and suggest different workload",
      disabled: true,
      disabledTooltip: "Will be implemented in another ticket",
    },
  ];
}
