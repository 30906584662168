import React from "react";

import "./staffing_request_overview_tab.less";
import { StaffingRequestGeneralInformationCard } from "@src/features/staffing_request_details/components/staffing_request_general_information_card";
import { StaffingRequestInformationCard } from "@src/features/staffing_request_details/components/staffing_request_information_card";
import { RequestOverviewDetails } from "@src/types";
import { Spin } from "antd";

interface StaffingRequestOverviewTabProps {
  request: RequestOverviewDetails;
  status: string;
  isLoading: boolean;
}

const StaffingRequestOverviewTab: React.FC<StaffingRequestOverviewTabProps> = ({
  request,
  status,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <div className="staffing-request-overview">
        <Spin data-testid="loading-spinner" />
      </div>
    );
  }

  return (
    <div className="staffing-request-overview">
      <StaffingRequestGeneralInformationCard
        request={request}
        status={status}
      />
      <StaffingRequestInformationCard request={request} />
    </div>
  );
};

export default StaffingRequestOverviewTab;
